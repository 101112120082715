import * as React from "react"
// import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import '../styles/main.sass';
import Layout from '../components/layout';

const IndexPage = ({ pageTitle, children, data, location }) => {
  return (
    <Layout pageTitle="textschoen" location={location}>
      {children}
      <MDXRenderer>{data.mdx.body}</MDXRenderer>
    </Layout>
  );
};

export default IndexPage

export const query = graphql`
  query {
    # allMdx(sort: { fields: frontmatter___date, order: DESC }) {
    #   nodes {
    #     frontmatter {
    #       date(formatString: "MMMM D, YYYY")
    #       title
    #       path
    #     }
    #     id
    #   }
    # }
    mdx(frontmatter: { path: { eq: "home" } }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`;


// <main>
//   <div>
//     <title>{pageTitle}</title>
//     <nav>
//       <ul>
//         <li>
//           <Link to="/">Home</Link>
//         </li>
//         {data.allMdx.nodes.map((node) => (
//           <li key={node.id}>
//             <Link to={node.frontmatter.path}>{node.frontmatter.path}</Link>
//           </li>
//         ))}
//       </ul>
//     </nav>
//     <main>
//       <h1>{pageTitle}</h1>
//       {children}
//     </main>
//   </div>
// </main>;